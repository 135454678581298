/* google font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

/* layout */

:root {
  --primary: #800080;
  --primary-foreground: #CED4D9;
  --secondary: #f3f4f6;
  --secondary-foreground: #111827;
  --card: #e5e7eb;
  --card-foreground: #000000;
  --accent: #10b981;
  --accent-foreground: #ffffff;
  --error: #e7195a;
}


body {
  margin: 0;
  background: #CED4D9;
  font-family: "Poppins";
  height: 100%;
}

button {
  transition: transform 0.3s ease;
  /* Smooth transition */
}

button:hover {
  transform: scale(1.1);
  /* Scale up by 10% on hover */
}
.plan-buttons:disabled {
  background-color: grey;
  cursor: not-allowed;
  color: white; /* Optional: change text color to make it readable */
}


header {
  background: #CED4D9;
}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header a {
  color: #333;
  text-decoration: none;
}

.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
  padding-bottom: 60px; 
}

strong {
  font-weight: bold;
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  margin-left: 10px;
}


.company-card {
  background: #fff;
  border-radius: 10px;
  margin: 10px auto;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
}
.list-card {
  background: #fffdfdcc;
  border-radius: 10px;
  margin: 10px auto;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.company-active {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.company-card h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.company-card p {
  margin: 0;
  color: #555;
}

.company-link {
  color: var(--primary);
  text-decoration: none;
}

.company-link:hover {
  color: #333;
}

.visited {
  color: #3e3f41;
}



.back-container{
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--primary-foreground);
  width: max-content;
  border-radius: 10px;
  margin-left: 10px;
}

.back-link {
  color: var(--primary);
  /* Change this to your preferred text color */
  text-decoration: none;
  display: inline-block;
  /* Adjust spacing as needed */
}

.back-link:hover {
  text-decoration: underline;
  font-weight: bold;
}

.back-icon {
  margin-right: 5px;
  /* Adjust spacing between icon and text */
}

.map-container {
  margin-top: 50px;
  overflow: hidden;
  border-radius: 12px;
}

/* Style the search history dropdown */
.search-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.search-history-dropdown {
  position: relative;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
}

.search-history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background 0.3s;
}

.search-history-item:hover {
  background: #f1f1f1;
}

.remove-button {
  background-color: rgba(255, 255, 255, 0);
  color: black;
}





.company-details {
  display: flex;
  background: #fff;
  border-radius: 12px;
  margin: 20px auto;
  padding: 20px;
  padding-top: 0;
  position: relative;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
}

.column {
  flex: 1;
  padding: 10px;
}

.accounts,
.confirmation-statement,
.sic {
  margin-top: 20px;
}

.company-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.company-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.search {
  background-color: var(--primary);
  /* Button background color */
  color: #fff;
  /* Button text color */
  border: none;
  /* Remove button border */
  padding: 10px 20px;
  /* Add padding to the button */
  font-size: 14px;
  /* Button text size */
  cursor: pointer;
  /* Show pointer cursor on hover */
  border-radius: 5px;
  /* Add rounded corners to the button */
  transition: background-color 0.3s ease;
  /* Smooth transition for button color change */
}

.search:hover {
  background-color: #0056b3;
  /* Button background color on hover */
}


nav button {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--secondary);
  color: var(--secondary-foreground);
}



nav a:hover {
  background-color: rgba(243, 244, 246, 0.8);
}


.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.logo-img {
  width: 50px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-right: 10px;
  /* Adjust spacing between logo and text */
}

.logo-text {
  margin: 0;
  /* Remove default margin */
}

/* auth forms*/
form.signup,
form.login,
form.reset-password,
form.forgot-password {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

input.error {
  border: 2px solid red;
  background-color: #fdd;
}


/* Add loading styles for form submission */
form.forgot-password button[disabled] {
  background-color: #fff;
  /* Hide text */
  content: '';
  /* Add empty content */
  display: inline-block;
  width: 30px;
  /* Adjust width of spinner */
  height: 30px;
  /* Adjust height of spinner */
  border: 3px solid #131212;
  /* Border color of spinner */
  border-top-color: transparent;
  /* Hide top border to create spinner effect */
  border-radius: 50%;
  /* Make it a circle */
  animation: spin 0.8s linear infinite;
  /* Add rotation animation */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
    /* Rotate spinner */
  }
}

.marker-label {
  text-align: center;
  margin-top: 50px;
  /* Adjust as needed */
}

.loading {
  content: '';
  /* Create a pseudo-element for the spinner */
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 30%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Center the spinner precisely */
  width: 60px;
  /* Adjust size as needed */
  height: 60px;
  /* Adjust size as needed */
  border: 4px solid rgba(0, 0, 0, 0.1);
  /* Light border */
  border-top: 4px solid #333;
  /* Darker border on top */
  border-radius: 50%;
  /* Circle shape */
  animation: spin 1s linear infinite;
  /* Rotation animation */
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0)
  }
}

.loaded {
  margin-left: 20%;
  max-width: 100%;
  max-height: 100%;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


label,
input {
  display: block;
}

input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

select {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 5px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}



form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

/* Define styles for password match status */
.password-match {
  margin-top: 10px;
  padding: 5px;
  font-weight: bold;
}

.match {
  box-shadow: 0 0 15px 4px rgba(0, 255, 0, 0.5);
  /* Green shadow for match */
}

.no-match {
  box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.5);
  /* Red shadow for no match */
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

div.success {
  padding: 10px;
  background: #eaffeb;
  /* Greenish background */
  border: 1px solid #3fa766;
  /* Green border */
  color: #3fa766;
  /* Green text color */
  border-radius: 4px;
  margin: 20px 0;
}

input.error {
  border: 1px solid var(--error);
}


table {
  width: 100%;
  table-layout: fixed;
  font-family: 'Poppins';
}

.table-container {
  overflow-x: auto;
  border-radius: 14px;
  border: 1px solid black;
}

.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}

.tbl-content {
  height: 500px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

th {
  padding: 20px 15px;
  background-color: var(--primary);
  text-align: left;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}

.date-column {
  width: 20%;
  text-align: left;
}

.description-column {
  width: 60%;
  text-align: center;
}

.download-column {
  width: 20%;
  text-align: right;
}

td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-size: 14px;
  color: #000000;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

/* #content tr:nth-child(even) {
  background-color: #ddd;
}

#content tr:hover {
  background-color: #ddd;
} */

#content th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.pagination-container{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 60px;
  position: fixed;
  background-color: #CED4D9;
  border-radius: 10px;
  /* Adjust as needed */
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 10px;
  /* Adjust spacing between elements */
}

.link-button:disabled {
  color: grey;
  cursor: default;
  text-decoration: none;
}








/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}



.login-prompt-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
}

.login-prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.466);
  z-index: 1000;
}

.login-prompt-content {
  text-align: center;
}

.login-prompt-content h2 {
  margin-bottom: 10px;
}

.login-prompt-content p {
  margin-bottom: 20px;
}

.login-prompt-content button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-prompt-content button:hover {
  background-color: #0056b3;
}

.company-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000000;
}

.company-modal-content {
  background-color: #CED4D9;
  padding: 20px;
  border-radius: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

/* Optionally, you can style the close button */
.company-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}


.info {
  display: inline-flex;
  align-items: center;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-link {
  margin-right: 10px;
}


/*loader*/
.loader-container {
  position: fixed;
  /* Fix the container to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.loader {
  width: 40px;
  height: 20px;
  --c: no-repeat radial-gradient(farthest-side, #000 93%, #0000);
  background:
    var(--c) 0 0,
    var(--c) 50% 0;
  background-size: 8px 8px;
  position: relative;
  clip-path: inset(-200% -100% 0 0);
  animation: l6-0 1.5s linear infinite;
}

.loader:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 12px;
  background: #000;
  left: -16px;
  top: 0;
  animation:
    l6-1 1.5s linear infinite,
    l6-2 0.5s cubic-bezier(0, 200, .8, 200) infinite;
}

.loader:after {
  content: "";
  position: absolute;
  inset: 0 0 auto auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  animation: l6-3 1.5s linear infinite;
}

@keyframes l6-0 {

  0%,
  30% {
    background-position: 0 0, 50% 0
  }

  33% {
    background-position: 0 100%, 50% 0
  }

  41%,
  63% {
    background-position: 0 0, 50% 0
  }

  66% {
    background-position: 0 0, 50% 100%
  }

  74%,
  100% {
    background-position: 0 0, 50% 0
  }
}

@keyframes l6-1 {
  90% {
    transform: translateY(0)
  }

  95% {
    transform: translateY(15px)
  }

  100% {
    transform: translateY(15px);
    left: calc(100% - 8px)
  }
}

@keyframes l6-2 {
  100% {
    top: -0.1px
  }
}

@keyframes l6-3 {

  0%,
  80%,
  100% {
    transform: translate(0)
  }

  90% {
    transform: translate(26px)
  }
}

.loader-text {
  width: fit-content;
  font-weight: bold;
  font-family: 'Poppins';
  font-size: 20px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}

.loader-text:before {
  content: "LOADING..."
}

@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

/*landing page*/

.header-landing {
  width: 100%;
  max-width: 5xl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header-landing .logo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-landing .logo img {
  width: 40px;
  height: 40px;
}

.header-landing .logo span {
  font-size: 1.25rem;
  font-weight: bold;
}

.header-landing .button {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--secondary);
  color: var(--secondary-foreground);
}

.header-landing .button:hover {
  background-color: rgba(243, 244, 246, 0.8);
}

.main {
  width: 100%;
  max-width: 2xl;
  text-align: center;
}

.main h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 40px;
  margin-top: 40px;
}


.illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text-transition {
  margin-bottom: 0 !important;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  font-family: 'Quicksand';
}

/* Typing Effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 20ch;
  }
}

.typing {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
  max-width: 20ch;
  margin-bottom: 0 !important;
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: black;
  }
}

.text-text-200 {
  margin-top: 0 !important;
}

.main .card {
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  background-color: var(--card);
  color: var(--card-foreground);
  max-width: 350px;
}

.main .card .button {
  background-color: white;
  color: black;
  width: 100%;
  padding: 8px 0;
  margin-bottom: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db;
}

.main .card .button img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.main .card .divider {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.main .card .divider hr {
  flex-grow: 1;
  border-top: 1px solid #d1d5db;
}

.main .card .divider span {
  margin: 0 8px;
  color: #6b7280;
}

.main .card input {
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
}

.main .card .button-primary {
  width: 100%;
  padding: 8px 0;
  border-radius: 8px;
  background-color: var(--primary);
  color: var(--primary-foreground);
}

.main .card .button-primary:hover {
  background-color: rgba(216, 104, 29, 0.8);
}

.main .card p {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 16px;
}

.info-landing {
  max-width: 600px;
  margin-top: 30px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: x-large;
}

.font-bold {
  font-weight: bold;
  color: #5f5f5f;
  font-size: x-large;
}

.main .accent {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  background-color: var(--accent);
  color: var(--accent-foreground);
}

.main .accent .badge {
  background-color: #9333ea;
  color: white;
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 0.75rem;
  margin-right: 8px;
}

.main .faq {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 16px;
}

/* Hide the default marker */
details summary::-webkit-details-marker {
  display: none;
}

/* Add custom markers */
details summary::before {
  content: '+';
  margin-right: 10px;
  transition: transform 0.3s ease;
  display: inline-block;
  font-size: 24px;
  /* Adjust font size for custom markers */
  vertical-align: middle;
  /* Align marker vertically with text */
}

details[open] summary::before {
  content: '+';
  transform: rotate(45deg);
  /* Rotate to make '×' */
}

/* Additional styling */
.main details {
  border-bottom: 1px solid #d1d5db;
  padding-bottom: 8px;
  cursor: pointer;
}

.space-y-4>details {
  margin-bottom: 16px;
  padding: 10px;
}

.space-y-4>details[open] {
  background-color: #b2b7bb;
  border-radius: 10px;
}


.space-y-4>details>summary {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
  /* Ensure the ::before content displays correctly */
  vertical-align: middle;
  /* Align text vertically with marker */
}

.space-y-4>details>.details-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* Transition for max-height */
}

.space-y-4>details[open]>.details-content {
  max-height: 500px;
  /* Adjust as necessary to accommodate content */
  transition: max-height 0.3s ease;
  /* Ensure transition also applies when closing */
}

.space-y-4>details>p,
.space-y-4>details>ul {
  margin: 10px 0;
  padding-left: 30px;
  /* Adjust to create space between marker and text */
  text-align: left;
}

.space-y-4>details>ul>li {
  list-style-type: disc;
  margin-left: 10px;
  /* Adjust to align bullet with text */
}


.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #6b7280;
  background-color: #CED4D9;
}


.privacy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.privacy-policy {
  max-width: 900px;
}

.privacy-policy h1 {
  font-size: 3rem;
  font-family: 'Poppins';
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid #333;
}

.userCompany-container {
  display: flex;
  flex-wrap: wrap;
}

.userCompany-container.list {
  display: block;
}

.userCompany-item {
  flex-grow: 1;
  flex-basis: 200px;
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 14px;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.05);
  position: relative;
}

.userCompany-item.list {
  flex-basis: 100%;
  margin: 10px 0;
}

.user-info-container.grid {
  display: block;
}

.user-info-container.list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.email-container {
  flex: 2.5;
}
.accordion-container {
  flex: 7;
}
.accordion-container.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.userCompany-item h4 {
  margin: 0 0 10px 0;
  font-size: 1em;
  color: var(--primary);
}

.userCompany-item p {
  padding: 3px;
  margin: 0;
  font-size: 0.9em;
  color: #555;
}


.edit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}





/*modal*/
/* Modal styles */
.center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  text-align: center;
  position: relative;
}

.modal-content-edit {
  background-color: white;
  width: 500px;
  padding: 20px;
  max-width: 90%;
  max-height: 80%;
  overflow: auto;
  text-align: flex-start;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  z-index: 9999;
  /* Ensure it appears above the modal content */
}

/* Modal close button styles */ 
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
}


.modal-close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  z-index: 9999;
  /* Ensure it appears above the modal content */
}


/*input field*/
.wrap-input-18 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*PRICING STYLES*/
.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.pricing-title {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  color: #2d3748;
  margin: 0;
}

.pricing-subtitle {
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  color: #4a5568;
}

.divider-pricing {
  margin: 1rem auto;
  width: 700px;
  height: 4px;
  background-color: #2d3748;
}

.pricing-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pricing-card {
  background-color: #edf2f7;
  border-radius: 0.8rem;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  flex: 1 1 30%;
  max-width: 350px;
  margin: 1rem;
  transition: transform 0.3s ease;
  height: fit-content;
}

.pricing-card:hover {
  transform: translateY(-10px);
}

.pricing-card-inner {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
}

.pricing-card-header {
  background-color: var(--primary);
  color: #1a202c;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.pricing-card-content {
  text-align: center;
}

.pricing-card-content p{
  color: #2d3748;
  font-size: 14px;
}

.plan-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
  margin: 0;
}

.plan-price {
  font-size: 1rem;
  color: #4a5568;
  margin: 0;
}

.features {
  margin-top: 1rem;
}

.features ul {
  list-style: none;
  padding: 0;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.feature-icon {
  margin-right: 0.5rem;
}

.icon {
  color: var(--primary);
}

.select-button-container {
  text-align: center;
  margin-top: 1rem;
}

.select-button {
  background-color: var(--primary);
  color: white;
  padding: 1rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.5s ease;
  width: 90%;
}

.select-button:hover {
  background-color: #e67e1c;
}

.free-plan {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7fafc;
  border-radius: 10px;
  padding: 1rem;
}

.free-plan strong {
  margin: 0;
  color: #2d3748;
  font-size: large;
}

.inline-input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-input-icon input {
  margin-right: 15px; /* Adjust the spacing between input and icon */
}

.product-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.add-product-form{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  width: 350px;
  padding: 20px;
  align-items: stretch;
  justify-content: center;
  margin-top: 20px;
}


/* Calendar styles */

.rbc-event {

  /* Set font family */
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;

  /* Set font family */
}
.rbc-today {
  background-color: #99bfdb !important;
}


/* Customize event title style */
.MyCalendar-event-title {
  font-weight: bold !important; /* Bold event title */
}

.pie-container-outer{
  display: flex;
  justify-content: center; 
  margin-bottom: 20px;
  width: 400px;
}
.pie-container{
  width: 400px;
}

.canvas{
  width: 400px !important;
  height: 400px !important;
}
.calendar{
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px
}
.calendar-container{
  position: relative;
}
.legend{
  display: flex;
  align-items: center;
}
.filters{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 10px 
}
.dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  background-color: white;
  z-index: 1000;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.dropdown::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}



/* Container for the entire Filing History section */
.filing-history-container {
  display: flex;
  height: 650px; /* Full viewport height */
  background-color: #f7f7f7;
  border-radius: 14px;
}

/* Sidebar on the left side (folders for categories) */
.filing-history-sidebar {
  min-width: 300px !important;
  max-width: 300px !important;
  background-color:  #4A0072; /* Dark background color */
  color: #fff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Allows scrolling if content overflows */
  border-radius: 14px 0 0 14px;
}


.filing-history-sidebar h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.filing-history-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filing-history-sidebar li {
  background-color: #9c27b0; /* Primary purple */
  color: #fff;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.filing-history-sidebar li.active {
  background-color: #ab47bc; /* Slightly lighter purple for active state */
}

.filing-history-sidebar li:hover {
  background-color: #7b1fa2; /* Hover state */
}

.filing-history-sidebar li i {
  margin-right: 10px;
  font-size: 20px;
}

.filing-history-sidebar li span {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
}

.filing-history-sidebar li:last-child {
  margin-bottom: 0;
}

/* Main content area on the right side (filing history) */
.filing-history-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Allows scrolling if content overflows */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.filing-history-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}


.filing-history-item ul {
  list-style: none;
  padding: 0;
}

.filing-history-item li {
  display: flex;
  align-items: center;
  background-color: #f3e5f5; /* Light purple background for each item */
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.filing-history-item li .filing-icon {
  margin-right: 15px;
  font-size: 24px;
  color: #6a1b9a; /* Primary purple color for the icon */
}

.filing-history-item li .filing-details {
  flex-grow: 1;
}

.filing-history-item li a {
  color: #6a1b9a;
  text-decoration: underline;
}

.filing-history-item li.category-accounts {
  border-left: 4px solid #7b1fa2; /* Account-specific border color */
}

.filing-history-item li.category-gazette {
  border-left: 4px solid #512da8; /* Gazette-specific border color */
}

.filing-history-item li.category-confirmationstatement {
  border-left: 4px solid #303f9f; /* Confirmation statement-specific border color */
}

.filing-history-item li.category-incorporation {
  border-left: 4px solid #1976d2; /* Incorporation-specific border color */
}

.filing-history-item li.category-insolvency {
  border-left: 4px solid #0288d1; /* Insolvency-specific border color */
}

.filing-history-item li.category-officers {
  border-left: 4px solid #0097a7; /* Officers-specific border color */
}

.filing-history-item li.category-personswithsignificantcontrol {
  border-left: 4px solid #00796b; /* Persons with significant control-specific border color */
}

.filing-history-item li.category-annualreturn {
  border-left: 4px solid #388e3c; /* Annual return-specific border color */
}

.filing-history-item li.category-resolution {
  border-left: 4px solid #f57c00; /* Resolution-specific border color */
}
.filing-history-item li.category-address {
  border-left: 4px solid #5bd10c; /* Resolution-specific border color */
}

/* Hover effects */
.filing-history-item li:hover {
  background-color: #e1bee7; /* Slightly darker purple on hover */
}

/* Loading Spinner */
.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 100px auto;
}

/* Card styling for ul elements */
.filing-history-item ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
  margin: 0;
}

/* Individual card styling for li elements */
.filing-history-item ul li {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  width: calc(33.333% - 20px); /* Three cards per row with gap */
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.filing-history-item ul li:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.filing-history-item ul li p {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
}

.filing-history-item ul li a {
  text-decoration: none;
  color: #3498db;
  font-weight: bold;
}

.filing-history-item ul li a:hover {
  text-decoration: underline;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
    /* Adjust font size for better readability */
  }

  header .container {
    padding: 10px;
    /* Reduce padding for header */
  }

  .main h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
  }

  .logo-img {
    width: 50px;
    /* Adjust the width as needed */
    height: auto;
    /* Maintain aspect ratio */
    margin-right: 10px;
    /* Adjust spacing between logo and text */
  }

  .logo-text {
    margin: 0;
    /* Remove default margin */
    font-size: 1.5rem;
    /* Adjust font size as needed */
    white-space: nowrap;
    /* Prevent text from wrapping */
  }

  nav {
    display: flex;
    align-items: center;
  }

  nav a {
    margin-left: 10px;
  }

  nav button {
    background: #fff;
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 6px 10px;
    border-radius: 4px;
    font-family: "Poppins";
    cursor: pointer;
    font-size: 1em;
    margin-left: 10px;
    margin-right: 10px;
  }

  .profile-link {
    display: none;
  }

  nav a:hover {
    text-decoration: underline;
  }

  form.signup,
  form.login,
  form.reset-password,
  form.forgot-password {
    max-width: 300px;
    /* Reduce form width for smaller screens */
    margin: 20px auto;
    /* Adjust margin for centering */
    padding: 10px;
    /* Reduce form padding */
  }

  input,
  textarea,
  select {
    margin-bottom: 10px;
    /* Reduce margin bottom for form elements */
  }

  button {
    padding: 10px;
    /* Increase button padding for touch targets */
    font-size: 10px;
    margin: 4px;
    /* Adjust button font size */
  }

  .password-match {
    font-size: 12px;
    /* Adjust font size for password match status */
  }

  div.error,
  div.success {
    padding: 8px;
    /* Reduce padding for error and success messages */
    margin: 10px 0;
    /* Reduce margin for error and success messages */
  }

  .userCompany-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
  }
  .search-input {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  .wrap-input-18 {
    display: block;
  }
  .layout-btn {
    display: none;
  }
  .company-details {
    display: block;
  }
  .pie-container-outer{
    display: block;
    width: 250px;
  }
  .pie-container{
    display: block;
    width: 250px;
  }
  .canvas{
    width: 250px !important;
  }
  .calendar-container{
    display: block;
    ;
  }
  .calendar{
    display: block;
  }
  .filters{
    display: block;
    padding: 10px;
    justify-content: space-between;
  }

  table {
    width: 100%;
    table-layout: auto; /* Adjusted for better flexibility on small screens */
    font-family: 'Poppins';
  }

  .table-container {
    overflow-x: auto;
    border-radius: 14px;
    border: 1px solid black;
  }

  .tbl-header {
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%; /* Ensures the header takes full width */
  }
  .tbl-header thead {
    width: 100%;
  }

  .tbl-content {
    height: auto; /* Adjusts height for better visibility */
    max-height: 450px; /* Limits max height to prevent excessive scrolling */
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  th {
    padding: 10px 5px; /* Reduced padding for mobile */
    background-color: var(--primary);
    text-align: left;
    font-size: 12px; /* Smaller font size for better fit */
    color: #ffffff;
    text-transform: uppercase;
  }

  .date-column {
    width: 30%; /* Adjusted for better spacing */
    text-align: left;
  }

  .description-column {
    width: 50%; /* Reduced width to fit on small screens */
    text-align: center;
  }

  .download-column {
    width: 20%;
    text-align: right;
  }
  .download-column-table{
    
    text-align: right;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  td {
    padding: 10px; /* Reduced padding for a compact layout */
    text-align: left;
    vertical-align: middle;
    font-size: 12px; /* Smaller font size for better readability */
    color: #000000;
    border-bottom: solid 1px rgb(255, 255, 255);
  }
  /* Adjust the main container height */
  .filing-history-container {
    flex-direction: column;
    height: auto; /* Allow height to adjust based on content */
  }

  /* Adjust sidebar width for mobile view */
  .filing-history-sidebar {
    min-width: 89% !important;
    max-width: 89% !important;
    border-radius: 14px 14px 0 0; /* Rounded corners on the top only */
  }

  /* Center the sidebar header */
  .filing-history-sidebar h3 {
    font-size: 16px;
    text-align: center;
  }

  /* Adjust the list items in the sidebar */
  .filing-history-sidebar li {
    padding: 8px 10px;
    font-size: 14px;
    margin-bottom: 8px;
    justify-content: space-between;
  }

  /* Adjust the content area to fit below the sidebar */
  .filing-history-content {
    padding: 15px;
    border-radius: 0 0 14px 14px; /* Rounded corners on the bottom only */
  }

  /* Stack the filing items vertically on mobile */
  .filing-history-item ul li {
    width: 100%; /* Make each item full width */
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
  }

  /* Adjust icon and text size */
  .filing-history-item li .filing-icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .filing-history-item li .filing-details p {
    margin: 5px 0;
    font-size: 12px; /* Reduce font size for better fit */
  }

  .filing-history-item li a {
    font-size: 14px;
  }

  /* Adjust loading spinner size */
  .loading-spinner {
    width: 40px;
    height: 40px;
    border-width: 6px;
  }
}